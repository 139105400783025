import React, { useEffect } from 'react'
import Navbar from './Components/Navbar/Navbar'
import Hero from './Components/Hero/Hero'
import Team from './Components/Team/Team'
import Footer from './Components/Footer/Footer'
import Contact from './Components/Contact/Contact'
import Timeline from './Components/Timeline/Timeline'
import Feature from './Components/Features/Feature'
import Photos from './Components/Photos/Photos'

const App = () => {

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
  }, []);
  
  return (
    <>
    <Navbar />
    <Hero />
    <Feature />
    <Timeline />
    <Photos />
    <Team />
    <Contact />
    <Footer />
    </>
  )
}

export default App