import React, {useState, useEffect} from 'react'
import './Feature.css'
import ScrollAnimation from 'react-animate-on-scroll';
import F1 from '../../assets/assign.png'
import F2 from '../../assets/ecommerce.png'
import F3 from '../../assets/peso.png'

const Feature = () => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const newOpacity = scrollTop <= 300 ? 0 : 0 + (scrollTop - 299) / 100;
      setOpacity(scrollTop >= 300 ? 1 : newOpacity); 
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
     }, [])

    return (
        <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <section className='feature mb-5' style={{ opacity }} >
            <h1 className='text-center py-5' id="Services">Our Services</h1>
          <br />
            <div className='col-lg-12 d-inline-flex justify-content-center'>
            <div className='d-flex feature-card-2 flex-column flex-1 align-items-center justify-content-center'>
                  <div className='container-img'>
                    <img src={F1} className="icon-feature mb-5" alt='customize'></img>
                    </div>
                    <br />
                    <h4 className='mt-4 mb-3'>Customize your Needs</h4>
                    <br />
                    <p className='col-lg-5 text-center'>Choose the best features you for your current working environment</p>
                  </div>
                  <div className='d-flex feature-card-2 flex-column flex-1 align-items-center justify-content-center'>
                  <div className='container-img'>
                    <img src={F2} className="icon-feature mb-5" alt='ecommerce'></img>
                    </div>
                    <br />
                    <h4 className='mt-4 mb-3'>Get into the heart of your business</h4>
                    <br />
                    <p className='col-lg-5 text-center'>Know your business progress by getting insights and recommendations</p>
                  </div>
                  <div className='d-flex feature-card-3 flex-column flex-1 align-items-center justify-content-center'>
                  <div className='container-img'>
                    <img src={F3} className="icon-feature mb-5" alt='customize'></img>
                  </div>
                    <br />
                    <h4 className='mt-4 mb-3'>Affordable Pricing Schemes</h4>
                    <br />
                    <p className='col-lg-5 text-center'>Friendly pricing schemes that will help your business grow and expand</p>
                  </div>
            </div>
           
          
        </section>
        </ScrollAnimation>
        </>
      )
    
}

export default Feature