import React from 'react'
import './Photos.css'
import Carousel from 'react-bootstrap/Carousel';
import slide1 from '../../assets/1.jpg'
import slide2 from '../../assets/2.jpg'
import slide3 from '../../assets/3.jpg'
import slide4 from '../../assets/4.jpg'
import slide5 from '../../assets/5.jpg'
import ScrollAnimation from 'react-animate-on-scroll';


const Photos = () => {
  return (
    <>
      <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
      <h1 className='text-center mt-5' id="Team">Most Outstanding Startup</h1>
      <h5 className='text-center mb-5' >Pioneering Batch of<br/> DOST AdZU AZUL Technology Business Incubation </h5>
      <div className='d-flex justify-content-center'>
    <Carousel className='m-3 slide-container text-center'>
      <Carousel.Item className='slide-container'>
      <img src={slide1} className="slides" alt='Vende Prototype'></img>
      </Carousel.Item>
      <Carousel.Item>
      <img src={slide2} className="slides" alt='Vende Prototype'></img>
      </Carousel.Item>
      <Carousel.Item>
      <img src={slide3} className="slides slides-3" alt='Vende Prototype'></img>
      </Carousel.Item>
      <Carousel.Item>
      <img src={slide4} className="slides" alt='Vende Prototype'></img>
      </Carousel.Item>
      <Carousel.Item>
      <img src={slide5} className="slides" alt='Vende Prototype'></img>
      </Carousel.Item>
    </Carousel>
    </div>
    </ScrollAnimation>
    </>
  )
}

export default Photos