import React from 'react'
import ContactForm from '../ContactForm/Contact/ContactForm';
import './Contact.css'
import ScrollAnimation from 'react-animate-on-scroll'
import plant from '../../assets/plant.png'
import invest from '../../assets/invest.png'

const Contact = () => {
  return (
    <>
    <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
     <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
    <div className='container p-5 w-100 form-div mb-5' id="Contact">
      <div className='row align-self-start justify-content-start'>
        <div className='px-5 col-9'> 
      <h2>Be a Seed Investor!&nbsp; <img src={plant} className='mail-icon' alt='chat'></img></h2>
      <h5 className='mb-5'>We'll get back to you asap!&nbsp;</h5>
    </div>
      </div>
    <div className='row align-self-start justify-content-center'>
      <div className='col-6'>
      <ContactForm />
      
      </div>
      <div className='col-5'>
    <ul>
      <li className='d-inline-flex align-items-center justify-content-center'><i className='fas fa-envelope envelope-icon'></i>&nbsp;&nbsp; <p className='h-100'>jbmlabsph@gmail.com</p></li>
      <li className='d-inline-flex align-items-center'><i className='fas fa-home font-icon'></i>&nbsp;&nbsp; <p className='m-2'>DOST-AdZU AZUL HUB, AdZU Lantaka Campus, N.S. Valderosa St., ZC</p></li>
    </ul>
    </div>
    </div>
    
    </div>
    </ScrollAnimation>
    </>
  );
}

export default Contact