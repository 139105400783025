import React, { useState } from 'react'
import './Team.css'
import Jet from '../../assets/jet.png'
import Mine from '../../assets/mine.png'
import Tian from '../../assets/xtian.png'
import ScrollAnimation from 'react-animate-on-scroll'

const Team = () => {

    return (
        <>
       
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <section className='feature mb-5'>
            <h1 className='text-center py-5'>The Team</h1>
          <br />
          



          <ScrollAnimation delay={200} animateIn='fadeIn'>
            <div className='d-inline-flex justify-contents-center col-lg-12 feature-container'>
           
                  <div className='d-flex feature-card-1 flex-column align-items-center col-lg-4 '>
                    <img src={Jet} className="icon-team" alt='jet'></img>
                    <br />
                    <h3 className='mt-4 mb-3'>Jet</h3>
                    <h6>Chief Executive Officer</h6>
                    <br />
                    <p className='text-center'>Assistant for School Systems<br />15 years Software development<br />Specializes in complex integrations<br />(API or hardware)</p>
                    </div>
                   
                  <div className='d-flex feature-card-2 flex-column align-items-center col-lg-4'>
                    <img src={Mine} className="icon-team" alt='mine'></img>
                    <h3 className='mt-4 mb-3'>Mine</h3>
                    <h6>Chief Product Officer</h6>
                    <br />
                    <p className='text-center'>Full Stack Engineer<br />4 years of Software development <br />Specializes in UI/UX and Quality Assurance</p>
                  </div>

                  <div className='d-flex feature-card-3 flex-column align-items-center col-lg-4'>
                    <img src={Tian} className="icon-team" alt='xtian'></img>
                    <h3 className='mt-4 mb-3'>Xtian</h3>
                    <h6>Chief Technical Officer</h6>
                    <br />
                    <p className='text-center'>MIS Design Specialist<br />18 years Software development<br />Specializes in highly algorithmic software & database management</p>
                  </div>
                  
            </div>
            </ScrollAnimation>
        </section>
        </ScrollAnimation>
        </>
      )
    
}


export default Team