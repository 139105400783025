import { useForm, ValidationError  } from '@formspree/react';
import React from 'react'
import './ContactForm.css'


const ContactForm = () => {
    const [state, handleSubmit] = useForm('xblrlpao');
  if (state.succeeded) {
      return <p>Thanks for sending us a message!</p>;
  }
  return (
    <>
    <div className='container '>
      
    <form className="px-4" onSubmit={handleSubmit}>
      <div className='row'>
      <label htmlFor="fullname">
        Full Name
      </label>
      <input
      className='form-control input mb-2'
        id="fullname"
        type="text" 
        name="fullname"
      />
      </div>
      <div className='row'>
      <label htmlFor="email">
        Email Address
      </label>
      <input
      className='form-control input mb-2'
        id="email"
        type="email" 
        name="email"
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      </div>
      <div className='row'>
       <label htmlFor="message">
       Reason for seeding
      </label>
      <textarea
      className='form-control mb-2'
        id="message"
        name="message"
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <p className='invest-note'>Note: We will send the company primer so you can check if our products align to your values.</p>
      </div>
      <div className='row justify-content-center'>
      <button className='btn button-sub w-50' type="submit" disabled={state.submitting}>
        Send it! <i className='far fa-paper-plane'></i>
      </button>
      </div>
    </form>
    </div>
    </>
  );
}

export default ContactForm