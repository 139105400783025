import React, { useState, useEffect } from 'react'
import './Hero.css'
import banner from '../../assets/hero.jpg'

const Hero = () => {

  const [opacity, setOpacity] = useState(1);



useEffect(() => {
  const handleScroll = () => {
    const newOpacity = 1 - window.scrollY / 300;
      setOpacity(newOpacity >= 0 ? newOpacity : 0);
    };

    window.addEventListener('scroll', handleScroll);

      //cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
   }, [])
  return (
    <>

    <div className='block-hero' style={{ opacity }}>
    <section className='header'>
    <img src={banner} className='hero' alt='woman-working'></img>
    </section>
    
    <section className='m-5'><h1 className='p-5 text-white text-center space-up'>Empowering MSME Women Entrepreneurs.<br />
    <p className='col-md-5 d-inline-flex text-center'>
    We are here to fuel the growth of MSMEs in Zamboanga City. We provide the tools, resources, and support to unlock potential and build thriving businesses that give a positive impact to society.</p><br />
    <div className='d-inline-flex text-center'><button className='btn btn-cta-hero'>Get Started</button></div></h1>
  
    </section>
    </div>
    </>
  )
}

export default Hero
