import React from 'react'
import './Navbar.css'
import logo from '../../assets/jbmlogo-1.png'
import AnchorLink from "react-anchor-link-smooth-scroll";

const Navbar = () => {
  return (
    <>
    <nav className='d-inline-flex justify-content-around'>
        <img src={logo} className='logo m-2' alt='Logo'></img>
        <ul className='d-inline-flex justify-content-around gap-3 align-items-center mr-2'>
            <li className='nav-link'><a href="/" className='p-2'>Home</a></li>
            <li className='nav-link'><AnchorLink href="#Services" className='p-2'>Services</AnchorLink></li>   
            <li className='nav-link'><AnchorLink href="#Journey" className='p-2'>Our Journey</AnchorLink></li>
            <li className='nav-link'><AnchorLink href="#Team" className='p-2'>The Team</AnchorLink></li>
            <div className='d-inline-flex justify-content-around gap-1 align-items-center'><li className='nav-link'><AnchorLink href="#Contact">Contact Us</AnchorLink></li>
            <button className='btn btn-cta d-inline-flex align-items-center gap-2'><AnchorLink href="#Contact" className='p-1'>Sign up <i className='fas fa-chevron-right arrow-right icon-nav'></i></AnchorLink></button>
            </div>
            
        </ul>
    </nav>
    </>
  )
}

export default Navbar