import React from 'react'
import './Footer.css'
import logo from '../../assets/jbmlogo-1.png'

const Footer = () => {
  return (
    <>
    <div className='footer-div col-lg-12 pt-4'>
    <div className='media-div row align-self-end'>
        <ul className='text-center col-12'>
          <li className='fb'><a href='https://www.facebook.com/jbmlabsph/'>Follow us &nbsp; &nbsp;<i className='fab fa-facebook media-icon'></i></a></li>
          <li><p className='text-center text-white'> &copy; 2024 <span className='font-logo'>JBMLabs PH.</span> All rights reserved.</p></li>
        </ul>
    </div>
    </div>

    </>
  )
}

export default Footer