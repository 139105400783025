import React from 'react'
import './Timeline.css'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import star from '../../assets/star.png'
import ScrollAnimation from 'react-animate-on-scroll';

const Timeline = () => {
  return (
    <>
    <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
    <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
     <h1 className='text-center py-5 mt-5' id="Journey">Our Journey</h1>
    <VerticalTimeline lineColor="#4E10D3">
    <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(78, 16, 211 )', color: '#fff', boxShadow: '0.5rem 0.5rem #FF91A4' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(78, 16, 211)' }}
    iconStyle={{ background: 'rgb(78, 16, 211)', color: '#fff' }}
    animate={true}
    
  >
    <h3 className="vertical-timeline-element-title">VisualBeans Inventory System
</h3>
    <h4 className="vertical-timeline-element-subtitle">2009</h4>
    <p>
    Early inventory systems focused primarily on tracking finished goods. While this basic system helped manage stock throughout the supply chain (ordering, storing, using, and selling), it didn't account for raw materials or work-in-progress. Modern systems track all inventory types, allowing businesses to optimize stock levels and avoid stockouts or excess inventory that ties up cash.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(78, 16, 211 )', color: '#fff', boxShadow: '0.5rem 0.5rem #FF91A4' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(78, 16, 211)' }}
    iconStyle={{ background: 'rgb(78, 16, 211)', color: '#fff' }}
    animate={true}
  >
    <h3 className="vertical-timeline-element-title">Online Inventory System Multibranch

</h3>
    <h4 className="vertical-timeline-element-subtitle">2013</h4>
    <p>
    In 2013, the inventory system took a major leap forward by incorporating multi-branch functionality. This advancement allowed businesses to track inventory levels across all their locations in real-time. This improved visibility into stock levels across the entire company, enabling better allocation of resources, streamlined transfers between branches, and more accurate forecasting.
    </p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(78, 16, 211 )', color: '#fff', boxShadow: '0.5rem 0.5rem #FF91A4' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(78, 16, 211)' }}
    iconStyle={{ background: '#F9C041', color: '#fff' }}
    animate={true}
    >
    <div className='d-inline-flex justify-content-around '><h3 className="vertical-timeline-element-title">Zamboanga Siege Civilian Harm and Casualties Monitoring System</h3> <img className='star-icon' src={star} title='Favorites' alt='star'></img></div>
    <h4 className="vertical-timeline-element-subtitle">2013</h4>
    <p>
    Our Founders are passionate about developing tools that make a difference in people's lives, just like this simple software. They created a desktop app to help the local government track individuals in need of assistance. Here in JBMLabs, we are committed to using technology for positive community outreach.
    </p>
  </VerticalTimelineElement>
    <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(78, 16, 211 )', color: '#fff', boxShadow: '0.5rem 0.5rem #FF91A4' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(78, 16, 211)' }}
    iconStyle={{ background: 'rgb(78, 16, 211)', color: '#fff' }}
    animate={true}
    
  >
    <h3 className="vertical-timeline-element-title">Online Integrated Multibranch Financial Reporting

</h3>
    <h4 className="vertical-timeline-element-subtitle">2014</h4>
    <p>
    This exclusive software solution helped one of our clients' businesses, with multiple branches, manage their finances efficiently. This software acts as a central hub for all financial data across different businesses all over the country. It makes tracking the financial health of the owner easier and well-accounted.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(78, 16, 211 )', color: '#fff', boxShadow: '0.5rem 0.5rem #FF91A4' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(78, 16, 211)' }}
    iconStyle={{ background: 'rgb(78, 16, 211)', color: '#fff' }}
    animate={true}
    
  >
    <h3 className="vertical-timeline-element-title">Inventory System w/ Salesmen Mobile App


</h3>
    <h4 className="vertical-timeline-element-subtitle">2017</h4>
    <p>
    After developing some mobile apps for fun, we pivoted to create a solution for a local distributor. This technology allows the company to efficiently track their employees, performance metrics, and inventory levels. The system integrates with an admin panel web app, providing the visionary owner with a centralized view of all their data.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(78, 16, 211 )', color: '#fff', boxShadow: '0.5rem 0.5rem #FF91A4' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(78, 16, 211)' }}
    iconStyle={{ background: '#F9C041', color: '#fff' }}
    animate={true}
    >
    <div className='d-inline-flex justify-content-around '><h3 className="vertical-timeline-element-title">COVID-19 Support Monitoring Platform
    </h3> <img className='star-icon' src={star} title='Favorites' alt='star'></img></div>

    <h4 className="vertical-timeline-element-subtitle">2020</h4>
    <p>
    Our CEO was part of the team that brought a comprehensive monitoring platform, in support of the local government and local health officials, regarding the COVID-19 pandemic. We've developed the system they requested, from scratch, in about a week. This is yet again one of our active participation to supporting local initiatives.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(78, 16, 211 )', color: '#fff', boxShadow: '0.5rem 0.5rem #FF91A4' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(78, 16, 211)' }}
    iconStyle={{ background: 'rgb(78, 16, 211)', color: '#fff' }}
    animate={true}
    
  >
    <h3 className="vertical-timeline-element-title">Vende - Online ERP w/ Smart Insights



</h3>
    <h4 className="vertical-timeline-element-subtitle">2024</h4>
    <p>
    As a part of our incubation program in DOST-AdZU AZUL Hub, we chose to focus on a software that has a possible direct effect on poverty alleviation, and serving the underserved sector of the economy. We envision to bridge the digital gap for MSMEs by providing them with tools for business management, customer relationships, and e-commerce possibilities.
    </p>
  </VerticalTimelineElement>
</VerticalTimeline>
</ScrollAnimation>
    </>
  )
}

export default Timeline